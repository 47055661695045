// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.
// import "./user_socket.js"

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html"
// Establish Phoenix Socket and LiveView configuration.
import { Socket } from "phoenix"
import { LiveSocket } from "phoenix_live_view"
import topbar from "../vendor/topbar"

import Alpine from "alpinejs"
import persist from '@alpinejs/persist'
import collapse from '@alpinejs/collapse'

import TimeAgo from "./timeago.js"
import { es } from 'date-fns/locale';
import $ from "jquery";

import BSKHooks from "./bsk_hooks"



window.Alpine = Alpine
window.$ = $
Alpine.plugin(persist);
Alpine.plugin(collapse);
Alpine.plugin(TimeAgo.configure({
    locale: es,
}));
Alpine.start();
console.log("Alpine");
console.log(window.document.readyState, "hola");
let Hooks = BSKHooks;

let csrfToken = document.querySelector("meta[name='csrf-token']")
console.log(csrfToken);
if (csrfToken != null) {
    csrfToken = csrfToken.getAttribute("content")
    let liveSocket = new LiveSocket("/live", Socket, {
        params: { _csrf_token: csrfToken },
        hooks: Hooks,
        dom: {
            onBeforeElUpdated(from, to) {
                if (from._x_dataStack) {
                    window.Alpine.clone(from, to)
                }
            }
        }
    })

    // Show progress bar on live navigation and form submits
    topbar.config({ barColors: { 0: "#29d" }, shadowColor: "rgba(0, 0, 0, .3)" })
    window.addEventListener("phx:page-loading-start", _info => topbar.show(300))
    window.addEventListener("phx:page-loading-stop", _info => topbar.hide())

    // connect if there are any LiveViews on the page
    liveSocket.connect()

    // expose liveSocket on window for web console debug logs and latency simulation:
    // >> liveSocket.enableDebug()
    // >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
    // >> liveSocket.disableLatencySim()
    window.liveSocket = liveSocket
}

window.onload((e) => {


    const s = window.document.getElementById("splash-sreen")
    console.log(e, s);
})

const links = document.querySelectorAll(".page-header ul a");
for (const link of links) {
    link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
    e.preventDefault();
    const href = this.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop;
    scroll({
        top: offsetTop,
        behavior: "smooth"
    });
}


console.log('comsis.mx');

let loginForm = document.getElementById("contacto");
$('#send-success').fadeOut(1);
$('#loading-enviado').fadeOut(1);
console.log('loginForm', loginForm);
if (loginForm != undefined)
    loginForm.addEventListener("submit", (e) => {
        e.preventDefault();
        var settings = {
            "url": "https://us-central1-encouraging-mix-111109.cloudfunctions.net/contact_email/send-message",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                "name": document.getElementById("nombre").value,
                "email": document.getElementById("correo").value,
                "mensaje": document.getElementById("mensaje").value,
                "message": "Mensaje desde la página: Desde CONCOMSIS " + document.getElementById("mensaje").value +
                    "\n Nombre: " + document.getElementById("nombre").value +
                    "\n Correo: " + document.getElementById("correo").value +
                    "\n Mensaje: " + document.getElementById("mensaje").value,
                "token_hash": "a1929158-c7d9-4c04-85de-c3163bbaaeae"
            }),
        };
        $('#contacto').fadeOut(800);
        $('#loading-enviado').fadeIn(800);
        $.ajax(settings).done(function(response) {
            console.log(response);
            $('#loading-enviado').fadeOut(500);
            $('.loading-area').fadeOut(1000);
            $('#send-success').fadeIn(1000);
            $('#nombre').val("");
            $('#correo').val("");
            $('#mensaje').val("");
        });
    });