import $ from "jquery";

const BSKHooks = {};
/*
    ConnHost

    Use:

    <div id="test" phx-hook="ConnHost" data-target={@myself}>
        test hook
    </div>

*/
BSKHooks.ConnHost = {
    mounted() {
        const url = window.document.getElementById("conn_host").dataset.url;
        if (this.el.dataset.target == undefined)
            this.pushEvent("conn-host", { host: url })
        else
            this.pushEventTo(this.el.dataset.target, "conn-host", { host: url })
    }
}
BSKHooks.Support = {
        mounted() {
            const url = window.document.getElementById("conn_host").dataset.url;
            if (this.el.dataset.target == undefined)
                this.pushEvent("conn-host", { host: url })
            else
                this.pushEventTo(this.el.dataset.target, "conn-host", { host: url })
        }
    }
    //import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
    //initMercadoPago('APP_USR-3b96ce27-0580-4db6-9468-d8811bca3c2a');

BSKHooks.Uploader = {
    mounted() {
        //console.log("Uploader", this.el);
        const inputElement = document.getElementById("photo_upload");
        console.log(inputElement);
        if (inputElement != undefined)
            inputElement.addEventListener("change", handleFiles, false);
        const _this = this;

        function load_icon(show) {
            const e = window.document.getElementById('photo_upload_icon');
            if (e != undefined)
                if (show) {
                    e.classList.remove('hidden');
                } else {
                    e.classList.add('hidden');
                }
        }

        function handleFiles() {
            const fileList = this.files; /* now you can work with the file list */
            console.log(fileList);
            const input = window.document.getElementById('photo_upload');

            if (!input.files || input.files.length === 0) {
                // console.error('Por favor, selecciona una imagen.');
            } else {
                _this.pushEventTo(_this.el.dataset.target, "load_photos_icon", true)
                const file = input.files[0];
                const formData = new FormData();
                formData.append('token', _this.el.dataset.token_upload);
                formData.append('fileToUpload', file);
                console.log(formData);
                $.ajax({
                    url: 'https://comsis.mx/up.php',
                    type: 'POST',
                    data: formData,
                    dataType: 'JSON',
                    processData: false,
                    contentType: false,
                    success: function(data) {
                        console.log('Respuesta del servidor:', data);
                        input.value = "";
                        // Maneja la respuesta del servidor aquí si es necesario
                        _this.pushEventTo(_this.el.dataset.target, "img_upload", data)
                        _this.pushEventTo(_this.el.dataset.target, "load_photos_icon", false)


                    },
                    error: function(xhr, status, error) {
                        console.error('Error al enviar la imagen:', error);
                        //                _this.pushEventTo(_this.el.dataset.target, "load_photos_icon", false)


                    }
                });
            }
        }
    }
}
BSKHooks.PopUpImg = {
    mounted() {
        console.log(this.el, "asdasdasdasdasdasdadsads");
    }
}


export default BSKHooks;